<template>
  <v-container v-if="user">
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="reviews"
          sort-by="updatedTime"
          sort-desc
          :items-per-page="15"
          class="elevation-1"
        >
          <template v-slot:item.orderId="{ item }">
            <router-link :to="'/order/view/' + item.orderId">{{
              item.orderId
            }}</router-link>
          </template>

          <template v-slot:item.updatedTime="{ item }">
            {{ item.updatedTime | formatDateTime }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      uid: null,
      reviews: [],
      headers: [
        { text: 'OrderId', value: 'orderId' },
        { text: 'Reviewed By', value: 'sender.name' },
        { text: 'Overal Rating', value: 'producer_ratings.ratings.overall' },
        { text: 'Review Date', value: 'updatedTime' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      isAdmin: 'isAdmin'
    })
  },
  mounted() {
    this.uid =
      this.isAdmin && this.$route.params.uid
        ? this.$route.params.uid
        : this.user
        ? this.user.id
        : null
    if (this.uid) {
      this.$store.dispatch('loadReviews', { uid: this.uid }).then(list => {
        this.reviews = list
      })
    } else {
      console.error('unable to get this uid', this.uid)
    }
  }
}
</script>
